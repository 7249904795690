'use strict';

// Import Libraries
import React         from 'react';

// Import JS Modules
import FlyoutSection from './flyout-section'

const Wrapper = (props) => {
  if (!props.section.sublinks) return null;

  let $sublinks = props.section.sublinks.map((sublinks, i) => {
    return (
      <li key={sublinks.name+i}>
        <FlyoutSection section={sublinks} />
      </li>
    );
  });

  return (
    <li className="megamenu-sub-menu">
      <ul className="list-unstyled">
        { $sublinks }
      </ul>
    </li>
  );
}

export default Wrapper;
