'use strict';

// Import Libraries
import React         from 'react';
import classNames    from 'classnames';

// Import JS Modules
import FlyoutSection from './flyout-section'
import Wrapper       from './wrapper'
import { href, link_classes, megamenu_wrapper, handleFlyoutMoreClick, handleTopLevelKeyPress, handleFlyoutKeypress } from './utils';

const Flyout = (props) => {
  let $sublinks = (props.links.sublinks || []).map((sublink, i) => {
    if (megamenu_wrapper(sublink)) {
      return (
        <Wrapper key={sublink.name + i} section={sublink} />
      );
    }
    return (
      <li key={sublink.name + i}
          className={classNames("megamenu-sub-menu", link_classes(sublink) )} >
        <FlyoutSection section={sublink} />
      </li>
    );
  });

  return (
    <li className={classNames('nav-item dropdown mega', link_classes(props.links), {show: props.active, 'd-none': props.hidden})}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}>
      <a className="nav-link dropdown-toggle"
         href={href(props.links)}>
        {props.links.name}
      </a>
      <button className={classNames('border-0 bg-white nav-item-more px-1', {'show': props.active})}
        onClick={(event) => handleFlyoutMoreClick(event, props)}
        onKeyDown={(event) => handleTopLevelKeyPress(event, props)}
        aria-expanded={props.active ? "true" : "false"}
        aria-label={`Show submenu of ${props.links.name}`}
        aria-haspopup="true">
      </button>
      <div className={classNames('dropdown-menu', {show: props.active})} style={{left: props.positionLeft, right: props.positionRight}}>
        <a className="link-title" href={href(props.links)}
          onKeyDown={(event) => handleFlyoutKeypress(event, props)}
        >
          {props.links.name}
        </a>
        <ul className="list-unstyled columns"
          onKeyDown={(event) => handleFlyoutKeypress(event, props)}>
          {$sublinks}
        </ul>
      </div>
    </li>
  );
}

export default Flyout;
