'use strict';

// Import Libraries
import React from 'react';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';

// Import JS Modules
import Flyout from './flyout'
import Dropdown from './dropdown'
import {
  href, link_classes,
  megamenu_link_dropdown,
  megamenu_link_flyout
} from './utils';

function componentForMenuItem(link) {
  if (megamenu_link_flyout(link)) { return Flyout; }
  if (megamenu_link_dropdown(link)) { return Dropdown; }
}

class MegaMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      numberOfNavItemsWhichWillFit: Infinity
    };
  }

  componentDidMount() {
    const { menu: $menu } = this;

    if ($menu) {
      this.itemsPositions = [];
      const margin = 0.5 * (window.innerWidth - $menu.offsetWidth);

      Array.from($menu.getElementsByClassName('nav-item')).forEach(($navItem, i) => {
        // this is the accumulated width of each item in the menu
        const previousRight = (this.itemsPositions[i - 1] || {}).right,
          right = $navItem.offsetWidth + (previousRight || 0),
          centre = parseInt(($navItem.getBoundingClientRect().left +
            $navItem.getBoundingClientRect().right) / 2 - margin),
          flyoutWidth = $($navItem.getElementsByClassName('dropdown-menu')[0]).outerWidth();
        this.itemsPositions.push({ right, centre, flyoutWidth });
      });

      this.calculateVisible($menu.parentElement.offsetWidth);

      this.resizeObserver = new ResizeObserver(entries => {
        const parentElement = $menu.parentElement;
        if (entries.some(entry => entry.target === parentElement)) {
          this.calculateVisible(parentElement.offsetWidth);
        }
      });
      this.resizeObserver.observe($menu.parentElement);
    }
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  render() {
    const numberOfNavItemsWhichWillFit = this.state.numberOfNavItemsWhichWillFit - 1,
      showMore = numberOfNavItemsWhichWillFit <= this.props.links.length,
      { selected } = this.state;
    const $links = (this.props.links || []).map((link, i) => {
      const MenuItem = componentForMenuItem(link),
        positioningProperties = this.getFlyoutPositioning(i);

      if (MenuItem) {
        return (
          <MenuItem key={link.name + i}
            links={link}
            active={selected == i}
            hidden={showMore && i >= numberOfNavItemsWhichWillFit}
            positionLeft={positioningProperties.left}
            positionRight={positioningProperties.right}
            onMouseEnter={() => this.onMouseEnter(i)}
            onMouseLeave={() => this.onMouseEnter()} />
        );
      }

      return (
        <li key={link.name + i}
          className={classNames('nav-item', link_classes(link), { 'd-none': showMore && i >= numberOfNavItemsWhichWillFit })}>
          <a className="nav-link"
            href={href(link)}>
            {link.name}
          </a>
        </li>
      );
    });

    const $more = (() => {
      if (!showMore)
        return;

      const sublinks = this.props.links.slice(numberOfNavItemsWhichWillFit).
        map(link => {
          return {
            name: link.name,
            url: link.url
          };
        });

      return <Dropdown name="More"
        right
        links={{
          name: 'More',
          tag: 'nav-item-more-wrapper',
          sublinks
        }}
        active={selected == 'more'}
        onMouseEnter={() => this.onMouseEnter('more')}
        onMouseLeave={() => this.onMouseEnter()} />;
    })();

    return (
      <nav ref={ref => this.menu = ref}
        className="navbar navbar-expand p-0">
        <ul className="navbar-nav">
          {$links}
          {$more}
        </ul>
      </nav>
    );
  }

  onMouseEnter(index) {
    this.setState(() => {
      return {
        selected: index
      };
    });
  }

  calculateVisible(width) {
    const numberOfNavItemsWhichWillFit = (itemsPositions => {
      let index = 0;
      for (const itemPos of itemsPositions) {
        if (itemPos.right > width) {
          return index;
        }
        index++;
      }

      return Infinity;
    })(this.itemsPositions);

    this.setState(() => {
      return {
        numberOfNavItemsWhichWillFit
      };
    });
  }

  getFlyoutPositioning(i) {
    if (this.itemsPositions && this.itemsPositions[i]) {
      const { right, centre, flyoutWidth } = this.itemsPositions[i],
        navbarWidth = this.menu.offsetWidth;

      if (flyoutWidth > navbarWidth) {
        return { left: ((navbarWidth - flyoutWidth) / 2), right: 'auto' };
      }
      if ((centre - (flyoutWidth / 2)) > 0 && (centre < navbarWidth / 2)) {
        return { left: (centre - (flyoutWidth / 2)), right: 'auto' };
      }
      if ((centre + (flyoutWidth / 2)) < navbarWidth && (centre > navbarWidth / 2)) {
        return { left: 'auto', right: (navbarWidth - centre - (flyoutWidth / 2)) };
      }
      if (centre < (navbarWidth / 2)) {
        return { left: '0', right: 'auto' };
      }
      return { left: 'auto', right: '0' };
    } else {
      return { left: '', right: '' };
    }
  }
}

export default MegaMenu;
