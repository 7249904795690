'use strict';

// Import Libraries
import React     from 'react';
import classNames from 'classnames';

// Import JS Modules
import { href, media_link, link_classes } from './utils';

const Sublink = ({ sublink }) => {
  const alt = !!sublink.alt ? sublink.alt : sublink.name;

  if (media_link(sublink)) {
    return (
      <li className={classNames('media-link', { 'd-none': sublink.hidden })}>
        <a className="megamenu-link"
           href={href(sublink)}>
          <img className="megamenu-link img-fluid mx-auto lazyload"
               data-src={sublink.src}
               alt={alt} />
        </a>
      </li>
    );
  }

  return (
    <li className={classNames({ 'd-none': sublink.hidden })}>
      <a className={classNames('megamenu-link', link_classes(sublink))}
         href={href(sublink)}>
        {sublink.name}
      </a>
    </li>
  );
}

export default Sublink;
