'use strict';

// Import Libraries
import React     from 'react';
import classNames from 'classnames';

// Import JS Modules
import Sublink from './sublink'
import { href } from './utils';

const FlyoutSection = (props) => {
  let $sublinks = (props.section.sublinks || []).map((sublink, i) => {
    return <Sublink key={sublink.name + '-' + i} sublink={sublink} />;
  });
  // props.section.hidden -> current link hide, if any of sublinks are hidden, they'll be hidden inside <Sublink />
  return (
    <div className={classNames('flyout-section', { 'd-none': props.section.hidden })}>
      <p className="section-title">
        <a className='megamenu-link' href={href(props.section)}>
          {props.section.name}
          <i className="fas fa-caret-right" />
        </a>
      </p>
      <ul className="list-unstyled">
        { $sublinks }
      </ul>
    </div>
  );
}

export default FlyoutSection;
